<template>
  <div class="detail-container">
    <div class="operator-bar">
      <el-button type="primary" @click="goEdit">编辑</el-button>
    </div>
    <div class="detail-info" v-loading="loading">
      <el-row>
        <el-col :span="3">标题：</el-col>
        <el-col :span="21">{{ info.title }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">缩略图：</el-col>
        <el-col :span="21"
          ><img :src="info.thumb" class="thumb" width="230"
        /></el-col>
      </el-row>
      <el-row v-if="info.author">
        <el-col :span="3">作者：</el-col>
        <el-col :span="21">{{ info.author }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">标签：</el-col>
        <el-col :span="21"
          ><template v-for="(tag, index) in info.tagList"
            >{{ tag.tagName
            }}<template v-if="index < info.tagList.length - 1"
              >，</template
            ></template
          ></el-col
        >
      </el-row>
      <el-row v-if="info.releaseTime">
        <el-col :span="3">发布时间：</el-col>
        <el-col :span="21">{{
          info.releaseTime | formatTime("{y}-{m}-{d} {h}:{i}")
        }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">阅读量：</el-col>
        <el-col :span="21">{{ info.realReadNum }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">文章内容：</el-col>
      </el-row>
      <el-row>
        <el-col :span="0"></el-col>
        <el-col>
          <el-scrollbar>
            <div v-html="info.content"></div>
          </el-scrollbar>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getArticleDetail, sentToBanner } from "@/api/article";
import { parseTime } from "@/utils/tool.js";

export default {
  data() {
    return {
      columnId: this.$route.meta.columnId,
      contentId: this.$route.query.contentId,
      info: {},
      loading: true,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getArticleDetail({ contentId: this.contentId }).then((res) => {
        this.info = res.data;
        this.loading = false;
      });
    },
    goEdit() {
      const path = this.$route.matched.slice(
        this.$route.matched.length - 2,
        this.$route.matched.length - 1
      )[0].path;
      this.$router.push(path + "/edit?contentId=" + this.contentId);
    },
  },
  filters: {
    formatTime(time, format) {
      return parseTime(time, format);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 500px;
}
.el-scrollbar__thumb {
  background: #666;
}
</style>
